<template>
  <g :id="`MaleAverageAvatarMobile_${uniqueIdSuffixA}`">
    <ellipse
      transform="matrix(4.531443e-02 -0.999 0.999 4.531443e-02 -207.1238 328.8679)"
      style="fill: #d0d0d0"
      cx="68.5"
      cy="272.8"
      rx="38.2"
      ry="38.2"
    />
    <path
      style="fill: #868686"
      d="M89.3,291.5l-3.9-1l-4.3-1.1l-3.1-0.8h-0.1c-1.2-0.3-2.1-1.4-2.1-2.7v-5.6l0,0
		c2.1-1.6,3.4-4.1,3.4-7v-0.7h0.1c0,0,1-0.5,1.3-0.7c0.7-0.5,1.2-0.8,1.2-1.9v-2c0-1-0.2-1.9-1.2-1.9h-1.1l-0.1-0.3v-0.2l-0.1-4
		c0-5.4-4.4-9.7-9.7-9.7H68c-5.4,0-9.7,4.4-9.7,9.7v4.1l0,0l-0.1,0.4h-1.3c-1,0-1.2,0.8-1.2,1.9v2c0,1,0.4,1.4,1.2,1.9
		c0.3,0.2,1.3,0.7,1.3,0.7h0.1v0.7c0,3,1.4,5.5,3.5,7l0,0l-0.1,0.1v5.5c0,1.3-1.3,2.4-2.5,2.7l-1.9,0.5l-4.3,1.2l-3.9,1.1
		c-1.6,0.4-2.9,1.4-3.7,2.6c2.7,3.1,6,5.8,9.6,7.7c1.7,0.9,3.4,1.6,5.3,2.2c1.6,0.5,3.2,0.8,4.9,1.1c1.3,0.2,2.7,0.3,4.1,0.3
		s2.7-0.1,4.1-0.3c1.7-0.2,3.3-0.6,4.9-1.1c1.8-0.6,3.6-1.3,5.3-2.2c3.7-1.9,6.9-4.5,9.6-7.7C92.2,292.9,90.9,291.9,89.3,291.5z"
    />
  </g>
</template>
<script>
export default {
  computed: {
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
  },
};
</script>
